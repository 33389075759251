/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false,
    /*,
    showcase: {
      submit_success_message: 'A member of the Amp team will review and publish <br>your story within 1-3 business days.',
      show_page_header: true,
      search_placeholder_text: 'Search'
    }*/
  },
  icons: {
    type: 'default',
    custom: {
      ic_expand_more: {
        viewbox: '0 0 14 14',
        content: '<path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="currentColor"/>'
      }
    }
  }
}
